import React from 'react';
import { SwitchContainer, SwitchLabel, SwitchToggle } from './Switch.styles';

const Switch = (props) => {
  const {
    firstText,
    secondText,
    roundness = 2,
    fluid=false,
    backgroundColor
  } = props;

  return (
    <SwitchContainer fluid={fluid} onClick={props.onClick} fontSize={14}>
      <SwitchLabel
        fluid={fluid}
        width={props.width}
        type="left"
        roundness={roundness}
      >
        {firstText}
      </SwitchLabel>
      <SwitchLabel
        fluid={fluid}
        width={props.width}
        type="right"
        roundness={roundness}
      >
        {secondText}
      </SwitchLabel>

      <SwitchToggle
        roundness={roundness}
        isToggle={props.switchToggle}
        fontColor={props.fontColor}
        backgroundColor={backgroundColor}
      >
        {props.switchToggle ? secondText : firstText}
      </SwitchToggle>
    </SwitchContainer>
  );
};

export default Switch;
