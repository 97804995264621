import { useEffect, useRef } from "react";

function useGetAnimationTranslation(showFilterDrawer) {

  const translationDistanceForAnimation = useRef();
  const middleBodyContainerRef = useRef();
  const leftWhenDrawerClosed = useRef(0);
  const leftWhenDrawerOpened = useRef(0);

  function getPageTopLeft(el) {
    const rect = el?.getBoundingClientRect();
    const docEl = document?.documentElement;
    return {
      left: rect?.left + (window.pageXOffset || docEl.scrollLeft || 0),
      top: rect?.top + (window.pageYOffset || docEl.scrollTop || 0)
    };
  }

  useEffect(() => {
    if (!leftWhenDrawerClosed.current || !leftWhenDrawerOpened.current) {
      if (!showFilterDrawer) {
        leftWhenDrawerClosed.current = getPageTopLeft(middleBodyContainerRef.current)?.left;
      } else {
        setTimeout(() => {
          leftWhenDrawerOpened.current = getPageTopLeft(middleBodyContainerRef.current)?.left;
          translationDistanceForAnimation.current = leftWhenDrawerOpened.current - leftWhenDrawerClosed.current;
        }, 600);
      }
    }
  }, [showFilterDrawer]);

  return { translationDistanceForAnimation, middleBodyContainerRef }
}

export default useGetAnimationTranslation