import React from 'react';

const LoaderIcon = props => {
  const waterSplashLoader = () => {
    return (
      <div className="waterSplashLoader" style={props.style}>
        <div></div>
        <div></div>
      </div>
    );
  };

  const ellipsis = () => {
    return (
      <div className="ds-ellipsis-loader" style={props.style}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  };

  const _renderLoader = type => {
    switch (type) {
      case 'water_splash_loader':
        return waterSplashLoader();
      case 'ellipsis':
      default:
        return ellipsis();
    }
  };

  return _renderLoader(props.type || '');
};

export default LoaderIcon;
