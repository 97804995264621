import { useEffect } from 'react';
import { useState } from 'react';
import { deviceWidth } from '@/utils/deviceWidth';
import { MAX_MOBILE_WIDTH } from '../utils/constants';

/**
 *
 * @param {*} ref reference to the div element whose height is checked from top
 * @param {*} generalConditon a set of condition that can overwrite the logic if true
 * @param {*} offsetTopHeight when element height distance is less than this value setstate triggers
 * @param {*} offsetBottomHeight when element height distance is less than this value setstate triggers
 * @returns {boolean} is element height is under offsetTopHeight or not
 */

function useTriggerChangeTopHeightMobile(
  ref,
  generalConditon,
  offsetTopHeight,
  offsetBottomHeight
) {
  if (generalConditon) generalConditon;
  const [isElementUnderOffset, setIsElementUnderOffset] = useState(false);
  useEffect(() => {
    if (deviceWidth <= MAX_MOBILE_WIDTH)
      window.addEventListener('scroll', checkifScrolledOffset);
    checkifScrolledOffset();
    return () => {
      window.removeEventListener('scroll', checkifScrolledOffset);
    };
  }, []);

  function checkifScrolledOffset() {
    if (ref.current?.getBoundingClientRect()?.top <= offsetTopHeight) {
      if (ref.current?.getBoundingClientRect()?.bottom <= offsetBottomHeight) {
        setIsElementUnderOffset(false);
      } else setIsElementUnderOffset(true);
    } else setIsElementUnderOffset(false);
  }
  return isElementUnderOffset;
}

export default useTriggerChangeTopHeightMobile;
