export const PROMO_TYPES = {
  GENERAL: 1,
  PRODUCT: 2,
  TAG: 3,
  CATEGORY: 4,
  COLLECTION: 5,
  BUYXGETY: 6,
};

export const PROMO_APPLICABILITY_TYPES = {
  PRODUCT: 1,
  TAG: 2,
  CATEGORY: 3,
  COLLECTION: 4,
};
