import styled from 'styled-components';
import { roundnessCalculator } from '../util';

export const ButtonBody = styled.button`
  cursor: pointer;
  text-align: center;
  outline: none;
  border-width: ${props => props.borderWidth || '1px'};
  border-style: solid;
  letter-spacing: 0.3px;
  text-transform: ${props => props.textTransform};
  width: ${props => (props.fluid ? '100%' : 'fit-content')};
  ${props => props?.width && `width : ${props?.width}`};
  font-weight: ${props => props.fontWeight || 500};
  font-size: ${props => props.fontSize || 14}px;
  display: ${props => props.display || 'block'};
  ${props => props.pt && `padding-top: ${props.pt}`};
  ${props => props.pb && `padding-bottom: ${props.pb}`};
  ${props => props.pl && `padding-left: ${props.pl}`};
  ${props => props.pr && `padding-right: ${props.pr}`};
  ${props =>
    props.px &&
    `
      padding-left: ${props.px};
      padding-right: ${props.px};
    `};
  ${props =>
    props.py &&
    `
      padding-top: ${props.py};
      padding-bottom: ${props.py};
    `};
  ${props => props.mt && `margin-top: ${props.mt}`};
  ${props => props.mb && `margin-bottom: ${props.mb}`};
  ${props => props.ml && `margin-left: ${props.ml}`};
  ${props => props.mr && `margin-right: ${props.mr}`};
  ${props =>
    props.mx &&
    `
      margin-left: ${props.mx};
      margin-right: ${props.mx};
    `};
  ${props =>
    props.my &&
    `
      margin-top: ${props.my}${props.my !== 'auto' && ``};
      margin-bottom: ${props.my}${props.my !== 'auto' && ``};
    `};
  ${props => props.maxWidth && `max-width: ${props.maxWidth}`};
  background-color: ${props =>
    props.variant === 'outline'
      ? 'transparent'
      : props.backgroundColor || '#000000'};
  border-radius: ${props => roundnessCalculator(props.roundness)};
  color: ${props =>
    props.variant === 'outline'
      ? props.backgroundColor
      : props.fontColor || '#ffffff'};
  box-shadow: ${props => !props.disabled && props.boxShadow};
  border-color: ${props => props.backgroundColor};
  border-color: ${props => props.borderColor};
  ${props => props?.whiteSpace && `white-space: ${props?.whiteSpace}`};
  &:hover {
    background-color: ${props => props.hoverEnabled && '#F8F8F8'};
  }
  ${props => props.isDisable && `opacity: 0.5`};
`;
