import { getStoredMB7SortAndFilterConfig } from '@/components/DesignSystem/MiddleBody/MiddleBody7/utils';
import { getFilteredItemsOptions } from '../redux/actions';
import store from '../redux/store';

export const getFilteredOptionsProducts = (
  filterOptions,
  pageNo = 0,
  extraField = { isMiddleBody7: false }
) => {
  const storeId = store.getState().storeReducer.store.store_id;
  const sorting_type = store.getState().catalogReducer?.sortType;
  const dispatch = store.dispatch;
  if (extraField?.isMiddleBody7) {
    const isNextPage = getStoredMB7SortAndFilterConfig()?.isNextPage;
    if (!isNextPage) {
      return {
        status: false,
        is_next_page: false,
      };
    }
  }
  return new Promise((resolve, reject) => {
    dispatch(
      getFilteredItemsOptions(
        {
          store_id: storeId,
          page_no: pageNo,
          sorting_type: sorting_type,
          filters: filterOptions,
        },
        (status, is_next_page) =>
          status ? resolve({ status, is_next_page }) : reject(status)
      )
    );
  });
};
