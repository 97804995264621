import { removeDsAdsRouteLink } from '@/utils/removeDsAdsRouteLink';
import React from 'react';

function VerticalReasonModal(props) {
  return (
    <div className="pl20px flex flex-column justify-between">
      <div className="flex pt20px pb20px top-section fw6">
        You are seeing this Ad<br></br>
        because owner of this<br></br>
        website is using the free<br></br>
        version
      </div>
      <div className="flex flex-column pb20px pt20px">
        <div className="flex black-90 fw6">
          if you own this website<br></br>
          then you can remove this.
        </div>
        <div
          className="pa12px vertical-removead-section mt12px"
          onClick={removeDsAdsRouteLink}
        >
          <div className="flex justify-center fw7">Remove this Ad</div>
        </div>
      </div>
    </div>
  );
}

export default VerticalReasonModal;
