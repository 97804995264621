/**
 * function to check if managed inventory is allowed
 * quantity and available_quantity comparison
 * @param {*} stockItem
 * @return {boolean} boolean
 */
export const itemQuantityValueComparison = (stockItem) => {
  if (
    !(
      stockItem?.managed_inventory &&
      stockItem?.available_quantity &&
      stockItem?.quantity
    )
  ) {
    return false;
  }

  return stockItem.quantity >= stockItem.available_quantity;
};
