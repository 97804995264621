import React, { useEffect } from 'react';
import { SortList } from '../../../../utils/constants';
import { MobileSortByArrow } from '@/assets/svgExports/MobileSortByArrow';

export function SortByCtaMobile(props) {
  const {
    selectedSortingText,
    toggleShowDropDown,
    showDropDown,
    handleSortType,
    isSortOpenAndMobile,
    isFilterOpenAndMobile,
  } = props;

  useEffect(() => {
    if (showDropDown) {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    };
  }, [showDropDown]);

  return (
    <div className="sortingTabMobile" onClick={toggleShowDropDown}>
      {!isSortOpenAndMobile() && !isFilterOpenAndMobile() && (
        <>
          <div className="flex mr10px">
            <MobileSortByArrow />
            <div className="flex items-center" style={{ transform: 'rotateZ(180deg)' }}>
              <MobileSortByArrow />
            </div>
          </div>
          <div className="sortingTabText">
            <span>Sort By </span>
          </div>
        </>
      )}
      {showDropDown && (
        <div className="dropDownContainerMobile">
          <div className="flex sortByHeading">
            Sort By
            <div className="borderDiv" />
          </div>
          {SortList.map((sortItem, idx) => {
            return (
              <div className="flex items-center mb20px" key={idx}>
                <input
                  className="pointer"
                  name="selectedSortingType"
                  type="radio"
                  id={`sortinglist-${sortItem.name}`}
                  value={sortItem.type}
                  checked={sortItem.name === selectedSortingText}
                  onChange={(e) => handleSortType(e, sortItem)}
                  onClick={(e) => e.stopPropagation()}
                />
                <label
                  className="f14px ml10px pointer"
                  onClick={(e) => e.stopPropagation()}
                  htmlFor={`sortinglist-${sortItem.name}`}
                >
                  {sortItem.name}
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
