import React from 'react';
import { ButtonBody } from './Button.styles';
import PropTypes from 'prop-types';

const Button = props => {
  const { label, ...args } = props;
  return <ButtonBody {...args}>{label}</ButtonBody>;
};

Button.propsTypes = {
  backgroundColor: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.any,
  display: PropTypes.string,
  roundness: PropTypes.number,
  width: PropTypes.string,
  textTransform: PropTypes.string,
  fontColor: PropTypes.string,
  pt: PropTypes.string,
  pl: PropTypes.string,
  pb: PropTypes.string,
  pr: PropTypes.string,
  py: PropTypes.string,
  px: PropTypes.string,
  mt: PropTypes.string,
  mb: PropTypes.string,
  ml: PropTypes.string,
  mr: PropTypes.string,
  mx: PropTypes.string,
  my: PropTypes.string,
  fluid: PropTypes.bool,
  borderColor: PropTypes.string,
  disabled: PropTypes.bool,
  fontSize: PropTypes.number,
  variant: PropTypes.string,
  whiteSpace: PropTypes.string,
};

export default Button;
