import { useEffect } from 'react';

function useIsClickOutside(ref, callback = null, ...callbackArgs) {
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function handleClickOutside(e) {
    if (!e.composedPath()?.includes(ref.current)) {
      e.stopPropagation();
      callback && callback(...callbackArgs);
    }
  }
}

export default useIsClickOutside;
