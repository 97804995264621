import { useRef } from 'react';
import { DropDownArrowSort } from '@/assets/svgExports/DropDownArrowSort';
import { SortList } from '@/utils/constants';
import useIsClickOutside from '@/utils/useIsClickOutside';

export function SortByCtaDesktop(props) {
  const {
    selectedSortingText,
    toggleShowDropDown,
    showDropDown,
    setShowDropDown,
    handleSortType,
  } = props;

  const dropDownElementRef = useRef();

  useIsClickOutside(dropDownElementRef, setShowDropDown, false);

  return (
    <div ref={dropDownElementRef} className="sortingTab">
      <div className="sortingTabText">
        <span>Sort By: </span>
        <span className="fw6 ml6px">{selectedSortingText}</span>
      </div>
      <div
        onClick={toggleShowDropDown}
        className="dropDownArrow pointer"
        style={{ '--showDropDownAngle': showDropDown ? '180deg' : '0deg' }}
      >
        <DropDownArrowSort />
      </div>
      {showDropDown && (
        <div className="dropDownContainer">
          {SortList.map((sortItem, idx) => {
            return (
              <div className="flex items-center mb20px" key={idx}>
                <input
                  className="pointer"
                  name="selectedSortingType"
                  type="radio"
                  id={`sortinglist-${sortItem.name}`}
                  value={sortItem.type}
                  checked={sortItem.name === selectedSortingText}
                  onChange={(e) => handleSortType(e, sortItem)}
                />
                <label
                  className="f14px ml10px pointer"
                  htmlFor={`sortinglist-${sortItem.name}`}
                >
                  {sortItem.name}
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
