interface extraFieldsType {
  maxImgSize?: number;
  minImgSize?: number;
}

export const componentTypeEnums = {
  CARD: 1,
  ITEM_THUMBNAIL: 2
}

export const imgSizeEnums = {
  LOW: 1,
  MID: 2,
  HIGH: 3,
};

// the srcset is creating a few problems where device pixel ratio is high (loading a lower resolution image)
// consider using getImageUrlFromCompressedImages for any such cases
export function getSrcSetFromCompressedImages(
  compressedImages = {},
  mainImage: string,
  extraFields: extraFieldsType,
) {
  const parsedCompressedImages = parseCompressedImageObj(compressedImages || {});
  return prepareSrcSet(
    parsedCompressedImages,
    mainImage,
    extraFields?.maxImgSize || imgSizeEnums.HIGH,
    extraFields?.minImgSize || imgSizeEnums.LOW,
  );
}

export function getImageUrlFromCompressedImages(
  compressedImages = {},
  mainImage: string,
  extraFields: extraFieldsType
) {
  const parsedCompressedImages = parseCompressedImageObj(compressedImages || {});
  const screenWidth = typeof window === 'undefined'? 1024: window.innerWidth;
  const maxImgSize = extraFields?.maxImgSize || imgSizeEnums.HIGH;

  let urlToReturn = mainImage;

  if (
    parsedCompressedImages[imgSizeEnums.LOW] &&
    (screenWidth <= 320 ||
    maxImgSize === imgSizeEnums.LOW)
  ) {
    urlToReturn = parsedCompressedImages[imgSizeEnums.LOW];
  } else if (
    parsedCompressedImages[imgSizeEnums.MID] &&
    ((screenWidth > 320 && screenWidth < 768) ||
    maxImgSize === imgSizeEnums.MID)
  ) {
    urlToReturn = parsedCompressedImages[imgSizeEnums.MID];
  } else if (parsedCompressedImages[imgSizeEnums.HIGH]) {
    urlToReturn = parsedCompressedImages[imgSizeEnums.HIGH];
  }

  return urlToReturn;
}

function prepareSrcSet(imgObj = {}, mainImage: string, maxImgSize = imgSizeEnums.HIGH, minImgSize = imgSizeEnums.LOW) {  
  let resString = '';

  const dpr = typeof window === 'undefined'? 2: window.devicePixelRatio

  if (minImgSize <= imgSizeEnums.LOW && maxImgSize >= imgSizeEnums.LOW && imgObj[imgSizeEnums.LOW]) {
    resString += `${imgObj[imgSizeEnums.LOW]} ${320 * dpr}w, `;
  }
  if (minImgSize <= imgSizeEnums.MID && maxImgSize >= imgSizeEnums.MID && imgObj[imgSizeEnums.MID]) {
    resString += `${imgObj[imgSizeEnums.MID]} ${480 * dpr}w, `;
  }
  if (minImgSize <= imgSizeEnums.HIGH && maxImgSize >= imgSizeEnums.HIGH && imgObj[imgSizeEnums.HIGH]) {
    resString += `${imgObj[imgSizeEnums.HIGH]} ${768 * dpr}w, `;
  }

  if (!maxImgSize || !resString) {
    resString += `${mainImage}`;
  }

  return resString;
}

// returns Object with image urls corresponding to low medium and high which is Represented by 1,2,3
function parseCompressedImageObj(compressedImages = {}) {
  const res = {};

  const keys = Object.keys(compressedImages) // keys are strings where numbers are separated by *. Eg. "200*200"

  // sorting keys (eg. key=200*200) to handle the case when we get jumbled order of image sizes.
  keys.sort((a, b) => {
    const first = parseInt(a.split("*")[0])
    const second = parseInt(b.split("*")[0])
    
    return first-second
  })

  if (keys.length >= 1) {
    res[imgSizeEnums.LOW] = compressedImages[keys[0]]
  }
  if (keys.length >= 2) {
    res[imgSizeEnums.MID] = compressedImages[keys[1]]
  }
  if (keys.length >= 3) {
    res[imgSizeEnums.HIGH] = compressedImages[keys[2]]
  }
  
  return res;
}

export function getMaxImgSizeForComponent(componentType: number) {
  if (componentType === componentTypeEnums.CARD){
    return imgSizeEnums.MID
  } else if (componentType === componentTypeEnums.ITEM_THUMBNAIL) {
    return imgSizeEnums.LOW
  }
  return imgSizeEnums.MID
}
