import { removeDsAdsRouteLink } from '@/utils/removeDsAdsRouteLink';
import { deviceWidth } from '@/utils/deviceWidth';
import { InfoIcon } from '@/assets/svgExports/InfoIcon';
import Image from 'next/image';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MAX_MOBILE_WIDTH } from '../../../utils/constants';
import ReasonModalHOC from '../DigitalShowroomAds/ReasonModalHOC';
import VerticalReasonModal from '../DigitalShowroomAds/VerticalReasonModal';
import checkIfThemePreview from '@/utils/checkIfThemePreview';

function DesktopDsAds(props) {
  const [showDesktopAdReasonModal, setShowDesktopAdReasonModal] = useState(false);
  const storeData = useSelector((state) => state.storeReducer.store);
  const isPreview = checkIfThemePreview();

  function toggleAdReasonModal() {
    setShowDesktopAdReasonModal(!showDesktopAdReasonModal);
  }

  if (deviceWidth <= MAX_MOBILE_WIDTH) return null;
  return (
    !isPreview && (
      <React.Fragment key={props.id}>
        {deviceWidth > MAX_MOBILE_WIDTH &&
          storeData.store_info?.non_premium_ad_url_desktop_vertical &&
          !storeData?.theme?.is_demo && (
            <div
              className={`flex flex-column digital-showroom__ads_footer items-end 
              digital-showroom__ads__sticky_on_header ${props.mr} ${props.pt} ${props.mb} ${props.ml}`}
            >
              <div className="flex f12px items-start">
                <div
                  className="flex container-width fw4 mb6px vertical-ad-text-color pointer"
                  onClick={() => setShowDesktopAdReasonModal(true)}
                >
                  Why I am seeing This?{' '}
                  <div className="pointer flex ml36px">
                    <InfoIcon size={16} secondaryColor={'#7F7F7F'} />
                    {showDesktopAdReasonModal && (
                      <ReasonModalHOC
                        toggleAdReasonModal={toggleAdReasonModal}
                        reasonModal={VerticalReasonModal}
                        mt={1.45}
                        ml={-1.21}
                        translateX={0}
                        translateY={0}
                        className={'vertical-reason-modal'}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="flex container-width gif-height pointer"
                onClick={removeDsAdsRouteLink}
              >
                <Image
                  src={storeData.store_info?.non_premium_ad_url_desktop_vertical}
                  width={187}
                  height={635}
                ></Image>
              </div>
            </div>
          )}
      </React.Fragment>
    )
  );
}

export default DesktopDsAds;
