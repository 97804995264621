import { getThemeColor } from '@/utils/getThemeColor';
import styled from 'styled-components';
import { MAX_MOBILE_WIDTH } from '@/utils/constants';

export const FilterDrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FilterHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 56px;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    min-height: 56px;
    z-index: 7;
  }
`;

export const FilterHeadingText = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  margin-left: 16px;
  align-items: center;
`;

export const ClearAllCta = styled.div`
  color: ${getThemeColor()};
  font-size: 12px;
  width: 124px;
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const FilterDrawerDesktop = styled.div`
  overflow: auto;
  height: calc(100vh - ${(props) => props?.headerHeight}px);
  padding-bottom: 40px;
  ${(props) => (props?.loader ? 'pointer-events : none' : ' ')};
`;
