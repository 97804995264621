export function ColoredRectangle(props) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.00390625"
        width="18"
        height="18"
        fill={props?.color ? props?.color : 'none'}
      />
    </svg>
  );
}
