import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFilteredItemsOptions } from '../redux/actions';
import { useSSRSelector } from '../redux';

function useTriggerInitialFilterOptions(additionalCondition = true) {
  const dispatch = useDispatch();
  const storeData = useSSRSelector((state) => state.storeReducer.store);
  const { sortType, filterAppliedCounter } = useSelector((state) => ({
    sortType: state.catalogReducer.sortType,
    filterAppliedCounter: state.catalogReducer.filterAppliedCounter,
  }));

  function callInitialGetFilteredItemsOptions() {
    const payload = {
      store_id: storeData?.store_id,
      page_no: 1,
      sorting_type: sortType,
      filters: [],
    };
    dispatch(getFilteredItemsOptions(payload));
  }

  useEffect(() => {
    if (!filterAppliedCounter && storeData?.store_id && additionalCondition) {
      callInitialGetFilteredItemsOptions();
    }
  }, [filterAppliedCounter, storeData?.store_id, additionalCondition]);
}

export default useTriggerInitialFilterOptions;
