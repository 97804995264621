import { getThemeColor } from '@/utils/getThemeColor';
import styled from 'styled-components';

export const FilterComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-left: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
`;

export const FilterComponentHeading = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 14px;
`;

export const FilterComponentListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterComponentListElement = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 14px;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  text-overflow: ellipsis;
  > input {
    transform: scale(1.25);
  }
  > label {
    max-width: 200px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

export const FilterComponentViewMore = styled.span`
  display: flex;
  flex-direction: row;
  margin-top: 14px;
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
  margin-left: 18px;
  color: ${getThemeColor()};
`;

export const FilterColoredComponent = styled.span`
  margin-left: 10px;
  margin-top: 2px;
`;

export const FilterComponentText = styled.span`
  margin-left: 10px;
  font-weight: 300;
  font-size: 14px;
`;

export const FilterComponentMobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  ${(props) => (props?.loader ? 'pointer-events: none' : '')};
`;

export const FilterComponentMobileHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  min-height: 90vh;
  border-right: 1px solid #ededed;
`;

export const FilterComponentMobileHeading = styled.div`
  display: flex;
  width: 32vw;
  padding: 16px;
  ${(props) => props?.isSelected && 'background : #FFFFFF'};
  border-bottom: 1px solid #ededed;
`;

export const FilterComponentMobileHeadingText = styled.span`
  font-size: 12px;
`;

export const FilterComponentMobileRightContainer = styled.span`
  display: flex;
  flex-direction column;
`;

export const FilterComponentMobileInput = styled.span`
  display: flex;
  flex-direction row;
  margin-top: 20px;
  align-items: center;
   > input {
    transform: scale(1.25);
  }
`;
