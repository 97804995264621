import { setSortType } from '@/redux/actions';
import { MobileFilterIcon } from '@/assets/svgExports/MobileFilterIcon';
import { SortList } from '@/utils/constants';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortByCtaDesktop } from '../SortingAndFiltersTab/Components/SortByCtaDesktop/SortByCtaDesktop';
import { SortByCtaMobile } from '../SortingAndFiltersTab/Components/SortByCtaMobile/SortByCtaMobile';
import { IS_MOBILE } from '@/utils/checkClientDeviceType';
import useTriggerChangeTopHeightMobile from '../../hooks/useTriggerChangeTopHeightMobile';

function SortingAndFiltersTab(props) {
  const { handleShowFilterDrawer } = props;
  const dispatch = useDispatch();

  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedSortingText, setSelectedSortingText] = useState(SortList[0].name);
  const { sortType } = useSelector((state) => ({
    sortType: state.catalogReducer.sortType,
  }));

  const showSortAndFilterMobileCta = useTriggerChangeTopHeightMobile(
    props.middleBodyRef,
    props.showFilterDrawer,
    150,
    360
  );

  useEffect(() => {
    if (props.showFilterDrawer && IS_MOBILE) {
      props.filterDrawerWrapperRef.current.scrollIntoView({
        behavior: 'instant',
        block: 'center',
      });
    }
  }, [props.showFilterDrawer]);

  useEffect(() => {
    if (showDropDown) {
      props.middleBodyRef.current.scrollIntoView();
    }
  }, [sortType]);

  function handleSortType(e, sortItem) {
    e.stopPropagation();
    dispatch(setSortType(sortItem.type));
    setSelectedSortingText(sortItem.name);
  }

  function toggleShowDropDown(e) {
    e.stopPropagation();
    setShowDropDown(!showDropDown);
  }

  function isSortOpenAndMobile() {
    return IS_MOBILE && showDropDown;
  }

  function isFilterOpenAndMobile() {
    return IS_MOBILE && props.showFilterDrawer;
  }

  function showFiltersCta() {
    if (!IS_MOBILE) {
      return !props.showFilterDrawer;
    }
    return true;
  }

  function isDrawerOpenAndMobile() {
    return props.showFilterDrawer && IS_MOBILE;
  }

  if (!showSortAndFilterMobileCta && IS_MOBILE) return null;

  return (
    <div
      className={`SortingAndFiltersTabWrapper ${
        isDrawerOpenAndMobile() ? 'Wrapper' : ''
      }`}
    >
      <div
        className={`SortingAndFiltersTab ${
          isDrawerOpenAndMobile() ? 'mobileDrawer' : ''
        }`}
      >
        {showFiltersCta() && (
          <div
            onClick={isSortOpenAndMobile() ? toggleShowDropDown : handleShowFilterDrawer}
            className="filterTab"
          >
            {!isSortOpenAndMobile() && !isFilterOpenAndMobile() && <MobileFilterIcon />}
            <span className="filterText">
              {`${
                isSortOpenAndMobile() || isFilterOpenAndMobile() ? 'Close' : 'Filters'
              }`}
            </span>
          </div>
        )}
        <div className="spacing-flex"></div>
        {!IS_MOBILE ? (
          <SortByCtaDesktop
            selectedSortingText={selectedSortingText}
            toggleShowDropDown={toggleShowDropDown}
            setShowDropDown={setShowDropDown}
            showDropDown={showDropDown}
            handleSortType={handleSortType}
          />
        ) : (
          !props.showFilterDrawer && (
            <SortByCtaMobile
              selectedSortingText={selectedSortingText}
              toggleShowDropDown={toggleShowDropDown}
              showDropDown={showDropDown}
              handleSortType={handleSortType}
              isFilterOpenAndMobile={isFilterOpenAndMobile}
              isSortOpenAndMobile={isSortOpenAndMobile}
            />
          )
        )}
      </div>
    </div>
  );
}

export default SortingAndFiltersTab;
