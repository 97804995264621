import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filtersCounter, saveFilteredOptions } from '@/redux/actions';
import { deviceWidth } from '@/utils/deviceWidth';
import {
  COLOR_FILTER_OPTION,
  FILTER_COUNTER_OPTIONS,
  MAX_MOBILE_WIDTH,
} from '@/utils/constants';
import { ColoredRectangle } from '@/assets/svgExports/ColoredRectangle';
import { setMB7SortAndFilterConfig } from '../../utils';
import {
  FilterComponentContainer,
  FilterComponentHeading,
  FilterComponentListContainer,
  FilterComponentListElement,
  FilterComponentMobileContainer,
  FilterComponentMobileHeading,
  FilterComponentMobileHeadingContainer,
  FilterComponentMobileHeadingText,
  FilterComponentMobileInput,
  FilterComponentMobileRightContainer,
  FilterComponentViewMore,
} from './FilterComponent.styles';

const MAX_ITEMS_SHOW_LIMIT = 5;

function FilterComponent(props) {
  const isMobile = deviceWidth <= MAX_MOBILE_WIDTH;
  const dispatch = useDispatch();
  const router = useRouter();
  const [showItemsLimit, setShowItemsLimit] = useState(MAX_ITEMS_SHOW_LIMIT);
  const [selectedMobileFilter, setSelectedMobileFilter] = useState({});
  const [toggleClick, setToggleClick] = useState(false);

  const { filteredOptions, filterOptionLoader, hideFiltersCategoryAndCollection } =
    useSelector((state) => ({
      filteredOptions: state.catalogReducer.filteredOptions,
      filterOptionLoader: state.commonReducer.filterOptionLoader,
      hideFiltersCategoryAndCollection:
        state.commonReducer.hideFiltersCategoryAndCollection,
    }));

  useEffect(() => {
    !!filteredOptions &&
    !!filteredOptions?.length &&
    isMobile &&
    hideFiltersCategoryAndCollection
      ? setSelectedMobileFilter(filteredOptions[2] || {})
      : removeCollectionOrCategoryFromPLP(getFilterType())
        ? setSelectedMobileFilter(filteredOptions[0] || {})
        : setSelectedMobileFilter(filteredOptions[1] || {});
  }, [hideFiltersCategoryAndCollection]);

  useEffect(() => {
    if (filteredOptions?.length) {
      setSelectedFilter();
    }
  }, [toggleClick]);

  useEffect(() => {
    setSelectedFilter();
  }, [filteredOptions]);

  function setSelectedFilter() {
    const newSelectedFilterForMobile = filteredOptions?.find(
      (item) => item.name === selectedMobileFilter?.name
    );
    newSelectedFilterForMobile && setSelectedMobileFilter(newSelectedFilterForMobile);
  }

  function getFilterType() {
    if (router?.query?.cid) return 2;
    else if (router?.query?.collectionId) return 1;
    return;
  }

  function handleViewMoreClick(ListElement) {
    const length = ListElement?.length ? ListElement?.length : ListElement;
    setShowItemsLimit(length);
  }

  function handleSelectedMobileFilter(itemName) {
    const newSelectedFilterForMobile = filteredOptions?.find(
      (item) => item.name === itemName
    );
    newSelectedFilterForMobile && setSelectedMobileFilter(newSelectedFilterForMobile);
  }

  /**
   * set is_selected value for sub_filter and changes filterApplied counter and saves
   * new filterOptions in reducer.
   * @param {*} e event on checkbox
   * @param {*} filterOptionName the name of filter option that is currently shown
   * @param {*} subFilterName the name of subfilter that is checked/unchecked by user.
   */

  function handleCheckboxClick(e, filterOptionName, subFilterName) {
    e?.stopPropagation();
    const tempFilterOptions = filteredOptions?.slice(
      filteredOptions,
      filteredOptions?.length
    );
    for (let i = 0; i < tempFilterOptions?.length; i++) {
      if (filterOptionName === tempFilterOptions[i]?.name) {
        for (let j = 0; j < tempFilterOptions[i]?.sub_filters?.length; j++) {
          if (subFilterName === tempFilterOptions[i].sub_filters[j]?.name) {
            e.target.checked
              ? (tempFilterOptions[i].sub_filters[j]['is_selected'] = true)
              : (tempFilterOptions[i].sub_filters[j]['is_selected'] = false);
            break;
          }
        }
      }
    }
    setToggleClick(!toggleClick);
    const filterCounter = e.target.checked
      ? FILTER_COUNTER_OPTIONS.INC
      : FILTER_COUNTER_OPTIONS.DEC;
    dispatch(filtersCounter(filterCounter));
    dispatch(saveFilteredOptions(tempFilterOptions));
    setMB7SortAndFilterConfig({ filterAppliedCounterChanged: true });
  }

  function renderViewMore(ListElement) {
    return (
      <FilterComponentViewMore
        onClick={() => {
          handleViewMoreClick(ListElement);
        }}
      >
        + {ListElement?.length - MAX_ITEMS_SHOW_LIMIT} more
      </FilterComponentViewMore>
    );
  }

  function renderViewLess(ListElement) {
    return (
      <FilterComponentViewMore
        onClick={() => {
          handleViewMoreClick(ListElement);
        }}
      >
        View Less
      </FilterComponentViewMore>
    );
  }

  function getTotalItemsCheckCount(filterOptionItem) {
    let totalCheckedCount = 0;
    !!filterOptionItem?.sub_filters &&
      !!filterOptionItem?.sub_filters?.length &&
      filterOptionItem?.sub_filters.forEach((item) => {
        item.is_selected && totalCheckedCount++;
      });
    return totalCheckedCount;
  }

  function removeCollectionOrCategoryFromPLP(filterType) {
    if (props?.hideCollectionFilter && filterType === 1) {
      return false;
    }
    if (props?.hideCategoryFilter && filterType === 2) {
      return false;
    }
    if (props?.hideTags && filterType === 3) {
      return false;
    }
    if (router?.query?.cid && filterType === 2) {
      return false;
    } else if (router?.query?.collectionId && filterType === 1) {
      return false;
    }
    return true;
  }

  function renderMobileFilterComponent() {
    return (
      <FilterComponentMobileContainer loader={filterOptionLoader}>
        <FilterComponentMobileHeadingContainer>
          {!!filteredOptions?.length &&
            filteredOptions?.map((filterOptionItem) => {
              return (
                !!filterOptionItem?.sub_filters?.length &&
                removeCollectionOrCategoryFromPLP(filterOptionItem?.type) && (
                  <FilterComponentMobileHeading
                    isSelected={filterOptionItem?.name === selectedMobileFilter?.name}
                    onClick={() => {
                      handleSelectedMobileFilter(filterOptionItem?.name);
                    }}
                    key={`${filterOptionItem.name}${filterOptionItem.type}`}
                  >
                    <FilterComponentMobileHeadingText>
                      {filterOptionItem.name}
                      {!!getTotalItemsCheckCount(filterOptionItem) && (
                        <span className="fw3 ml8px">{`(${getTotalItemsCheckCount(
                          filterOptionItem
                        )})`}</span>
                      )}
                    </FilterComponentMobileHeadingText>
                  </FilterComponentMobileHeading>
                )
              );
            })}
        </FilterComponentMobileHeadingContainer>
        <FilterComponentMobileRightContainer>
          {selectedMobileFilter?.sub_filters &&
            selectedMobileFilter.sub_filters.map((item) => {
              return (
                removeCollectionOrCategoryFromPLP(selectedMobileFilter?.type) && (
                  <FilterComponentMobileInput key={`subFilter-${item?.name}-${item?.id}`}>
                    <input
                      type="checkbox"
                      className="ml24px pointer"
                      checked={item?.is_selected}
                      id={`${selectedMobileFilter.name}-${item.name}`}
                      onClick={(e) =>
                        handleCheckboxClick(e, selectedMobileFilter.name, item.name)
                      }
                    />
                    {selectedMobileFilter.name === COLOR_FILTER_OPTION && (
                      <label
                        htmlFor={`${selectedMobileFilter.name}-${item.name}`}
                        className="pointer ml10px mt2px"
                      >
                        <ColoredRectangle color={item?.colour_code} />
                      </label>
                    )}
                    <label
                      htmlFor={`${selectedMobileFilter.name}-${item.name}`}
                      className="pointer ml10px fw3 f14px"
                    >
                      {item.name}
                    </label>
                  </FilterComponentMobileInput>
                )
              );
            })}
        </FilterComponentMobileRightContainer>
      </FilterComponentMobileContainer>
    );
  }

  function renderDesktopFilterComponent() {
    return (
      <>
        {!!filteredOptions?.length &&
          filteredOptions?.map((filterOption) => {
            return removeCollectionOrCategoryFromPLP(filterOption?.type) &&
              !!filterOption?.sub_filters?.length ? (
              <FilterComponentContainer key={`${filterOption.name}-filterOption`}>
                <FilterComponentHeading>
                  {filterOption.name}
                  {!!getTotalItemsCheckCount(filterOption) && (
                    <span className="fw3 ml8px">{`(${getTotalItemsCheckCount(
                      filterOption
                    )})`}</span>
                  )}
                </FilterComponentHeading>
                <FilterComponentListContainer>
                  {filterOption?.sub_filters?.slice(0, showItemsLimit).map((item) => {
                    return (
                      <FilterComponentListElement
                        key={`${filterOption.name}-${item.name}-filterOption-subFilters`}
                      >
                        <input
                          type="checkbox"
                          className="ml4px pointer"
                          checked={item?.is_selected}
                          id={`${filterOption.name}-${item.name}`}
                          onClick={(e) =>
                            handleCheckboxClick(e, filterOption.name, item.name)
                          }
                        />
                        {filterOption.name === COLOR_FILTER_OPTION && (
                          <label
                            htmlFor={`${filterOption.name}-${item.name}`}
                            className="pointer ml10px mt2px"
                          >
                            <ColoredRectangle color={item?.colour_code} />
                          </label>
                        )}
                        <label
                          htmlFor={`${filterOption.name}-${item.name}`}
                          className="pointer ml10px fw3 f14px"
                        >
                          {item.name}
                        </label>
                      </FilterComponentListElement>
                    );
                  })}
                  {filterOption?.sub_filters?.length > showItemsLimit &&
                    renderViewMore(filterOption?.sub_filters)}
                  {!!(
                    filterOption?.sub_filters?.length === showItemsLimit &&
                    showItemsLimit > MAX_ITEMS_SHOW_LIMIT
                  ) && renderViewLess(MAX_ITEMS_SHOW_LIMIT)}
                </FilterComponentListContainer>
              </FilterComponentContainer>
            ) : null;
          })}
      </>
    );
  }

  return <>{isMobile ? renderMobileFilterComponent() : renderDesktopFilterComponent()}</>;
}

export default FilterComponent;
