const OfferIcon = ({ height = 15, width = 15 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.236 8.62713L9.244 0.635127C9.07948 0.471113 8.88419 0.341193 8.66934 0.252817C8.4545 0.164442 8.22431 0.11935 7.992 0.120127H1.776C1.30538 0.121446 0.854417 0.308984 0.521638 0.641763C0.188859 0.974542 0.00132197 1.42551 2.59218e-06 1.89613V8.11213C-0.000398322 8.34653 0.0457096 8.57867 0.135656 8.79513C0.225602 9.01158 0.357599 9.20804 0.524002 9.37313L8.516 17.3651C8.68053 17.5291 8.87582 17.6591 9.09066 17.7474C9.30551 17.8358 9.53569 17.8809 9.768 17.8801C10.0012 17.8813 10.2322 17.8356 10.4473 17.7456C10.6624 17.6555 10.8572 17.5231 11.02 17.3561L17.236 11.1401C17.403 10.9773 17.5354 10.7826 17.6254 10.5675C17.7155 10.3524 17.7612 10.1213 17.76 9.88813C17.7584 9.41515 17.5701 8.96195 17.236 8.62713ZM9.768 16.1131L1.776 8.11313V1.89613H7.992V1.88713L15.984 9.87913L9.768 16.1131Z"
        fill="currentColor"
      />
      <path
        d="M4.2041 5.32422C4.75639 5.32422 5.2041 4.8765 5.2041 4.32422C5.2041 3.77193 4.75639 3.32422 4.2041 3.32422C3.65182 3.32422 3.2041 3.77193 3.2041 4.32422C3.2041 4.8765 3.65182 5.32422 4.2041 5.32422Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default OfferIcon;
