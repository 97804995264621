/**
 * Utility condition to check
 * for conditional render
 * to fix for layout inconsistencies
 * @param {number} isHeroBannerHeightCalculated
 * @param {boolan} isThemeId
 * @param {boolean} isShopRoute
 * @return {boolean}
 */
export const conditionalForMiddlebodyRender = (
  isHeroBannerHeightCalculated,
  isThemeId = false,
  isShopRoute = false
) => {
  return isHeroBannerHeightCalculated || isThemeId || isShopRoute;
};
