export function MobileFilterIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.58098 1.16602H12.4143C12.5343 1.20811 12.6431 1.27711 12.7323 1.36774C12.8216 1.45836 12.8889 1.56821 12.9291 1.68887C12.9693 1.80952 12.9814 1.93778 12.9644 2.06382C12.9474 2.18986 12.9017 2.31033 12.831 2.41602L8.66431 6.99935V12.8327L5.33098 10.3327V6.99935L1.16431 2.41602C1.09356 2.31033 1.04793 2.18986 1.03092 2.06382C1.01392 1.93778 1.02598 1.80952 1.0662 1.68887C1.10642 1.56821 1.17372 1.45836 1.26295 1.36774C1.35218 1.27711 1.46097 1.20811 1.58098 1.16602Z"
        stroke="black"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
