import { scrollOnPageLoad } from '@/utils/scrollOnPageLoad';
import { CLICKED_ITEM_OBJ } from '@/utils/constants';
import LocalStorageHelper from '@/utils/LocalStorageHelper';

export const MB7_SORT_FILTER_STORAGE_KEY = 'mb7SortAndFilterConfig';
export const MB7_SORT_FILTER_TEMP_STORAGE_KEY = 'mb7SortAndFilterConfigTemp';

export function scrollIfItemPreviouslyClicked() {
  const scrollConfig = scrollOnPageLoad();
  if (scrollConfig.toScroll) {
    try {
      const { y_scroll } = scrollConfig;
      setTimeout(() => {
        sessionStorage.removeItem(CLICKED_ITEM_OBJ);
        window.scrollTo({ top: y_scroll });
      }, 300);
    } catch (err) {
      console.log('timeout time error:', err);
    }
  }
}

export function getStoredMB7SortAndFilterConfig() {
  if (LocalStorageHelper.get(MB7_SORT_FILTER_STORAGE_KEY)) {
    return LocalStorageHelper.get(MB7_SORT_FILTER_STORAGE_KEY);
  }

  return {
    filterAppliedCounter: 0,
    sortType: 0,
    collectionId: null,
    cid: null,
    isNextPage: true,
    pageNo: 1,
    filteredItems: [],
    filteredOptions: [],
    allowReset: true,
    filterAppliedCounterChanged: false,
    sortTypeChanged: false,
  };
}

export function setMB7SortAndFilterConfig(data, temp = false) {
  const currentStoredData = getStoredMB7SortAndFilterConfig();
  const newData = { ...currentStoredData, ...data };
  if (temp) {
    LocalStorageHelper.add(MB7_SORT_FILTER_TEMP_STORAGE_KEY, newData);
  } else {
    LocalStorageHelper.add(MB7_SORT_FILTER_STORAGE_KEY, newData);
  }
}
