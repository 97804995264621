import { useCallback } from 'react';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';

// Takes in StyledComponent & passes a custom flag in it for builder
export const useProductListParentForBuilderHOC = (Component) => {
  const {
    widgetContextState: { isBuilder, previewDevice },
  } = useWidgetDndContextData();

  const isBuilderMobile = isBuilder && previewDevice === 'mobile';

  const HOC = useCallback(
    function (props) {
      return <Component {...props} isBuilderMobile={isBuilderMobile} />;
    },
    [isBuilderMobile]
  );

  return HOC;
};
