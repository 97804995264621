export function TickFill(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 29.25}
      height={props.height || 29.25}
      viewBox="0 0 29.25 29.25"
      {...props}
    >
      <path
        d="M14.625 0A14.625 14.625 0 1029.25 14.625 14.623 14.623 0 0014.625 0zm7.488 10.582l-9.4 9.443h-.008a1.27 1.27 0 01-.816.387 1.231 1.231 0 01-.823-.4l-3.941-3.939a.28.28 0 010-.4l1.256-1.248a.272.272 0 01.394 0l3.122 3.122 8.578-8.641a.278.278 0 01.2-.084.255.255 0 01.2.084l1.23 1.273a.277.277 0 01.008.403z"
        fill={props.color || '#000000'}
      />
    </svg>
  );
}
